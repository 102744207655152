/* SASS Stylesheet */

// Color definitions
$c-kutter-red: #D30132;
$c-kutter-red-dark: #980124;
$c-kutter-red-transparent: #D3013244;
$c-kutter-red-dark-transparent: #98012444;

$c-box-yellow: #daca0e;
$c-box-yellow-light: #f6f6c1;
$c-box-yellow-dark: #bf9925;
$c-box-yellow-transparent: #dedc0f44;

$c-box-orange: #ea882d;
$c-box-orange-light: #fce1c7;
$c-box-orange-dark: #bd620e;
$c-box-orange-transparent: #f58d0c44;

$c-box-green: #55c532;
$c-box-green-light: #d0f8c5;
$c-box-green-dark: #3d9422;
$c-box-green-transparent: #4ee42544;

$c-box-cyan: #34c293;
$c-box-cyan-light: #c6f5e6;
$c-box-cyan-dark: #22946c;
$c-box-cyan-transparent: #29E3A844;

$c-box-blue: #4597d2;
$c-box-blue-light: #c5e2f8;
$c-box-blue-dark: #1872a0;
$c-box-blue-transparent: #2591e444;

$c-box-red: #c70c0c;
$c-box-red-light: #f6c2c2;
$c-box-red-dark: #d82828;
$c-box-red-transparent: #dc1b1b44;

$c-box-magenta: #ff00ff;
$c-box-magenta-light: #ff66ff;
$c-box-magenta-dark: #990099;
$c-box-magenta-transparent: #ff00ff44;

$c-box-darkblue: #0644ff;
$c-box-darkblue-light: #7376ff;
$c-box-darkblue-dark: #2a3499;
$c-box-darkblue-transparent: #0F24D744;

$light-grey: #21252980;