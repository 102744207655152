/* SASS Stylesheet */

@import '../../styles/colors';

@keyframes blinking-glow {
  from { box-shadow: 0 0 6px #{$c-box-blue}00; }
  to { box-shadow: 0 0 6px $c-box-blue; }
}

.blinking {
  animation: blinking-glow 800ms ease-out infinite alternate;
}

#gantt-machine-list {
  display: inline-block;
  vertical-align: top;
  position: sticky;
  top: 0;
  left: 0;
  width: 320px;
  min-height: calc(100vh - 167px);
  z-index: 5;
  background: white;
  border-right: 1px solid #CCC;
}

.gantt-machine-list-item {
  position: relative;
  height: 41px;
  padding: 3px 0 3px 8px;
  border-bottom: 1px solid #CCC;
}

.gantt-machine-list-item-name {
  font-size: 14px;
  line-height: 18px;
  height: 20px;
}

.gantt-machine-list-item-color {
  position: absolute;
  top: 2px;
  right: 0;
  width: 6px;
  height: 36px;
  border-radius: 3px 0 0 3px;
  background: black;
  z-index: 1;
}

.gantt-machine-list-item-info {
  font-size: 11px;
  line-height: 20px;
  height: 14px;
  float: right;
  margin-right: 8px;
}

.gantt-machine-list-item-description {
  max-width: max-content;
  font-size: 11px;
  color: #aaaaaa;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transition: all 300ms ease;
}

.gantt-machine-list-item-description:hover {
  position: relative;
  width: max-content;
  color: black;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 0 3px #00000044;
  overflow: visible;
  z-index: 2;
  transition: all 300ms ease;
}

.tooltip-info{
  font-size: 11px;
  width: max-content;
  background-color: white;
  color: black;
  padding: 1px 4px;
  border-radius: 4px;
  position: relative;
  top:-17px;
  left: -4px;
  box-shadow: 0 0 3px #00000044;
  transition: opacity 300ms ease;
}

.gantt-machine-list-humanresource-name:hover + .tooltip-info {
  visibility: visible;
  opacity: 1;
}

.tooltip-info {
  visibility: hidden;
  opacity: 0;
}

.gantt-loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65px;
  width: calc(100% - 14px);
  height: calc(100% - 122px);
  background: #00000022;
  z-index: 20;
}

.gantt-loading-overlay.hidden {
  visibility: hidden;
  opacity: 0;
}

.gantt-content-container {
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 0;
  min-height: calc(100vh - 167px);
  overflow: hidden;

  user-select: none;
}

.gantt-content-highlight {
  position: absolute;
  top: 0;
  height: 100%;
  background: #47a7fb11;
  border-left: 1px solid #47a7fb;
  border-right: 1px solid #47a7fb;
}

@keyframes blinking {
  from { opacity: 1.0; }
  to { opacity: 0.4; }
}

.gantt-reservation-box-tooltip::before {
  position: absolute;
  top: 7px;
  left: -8px;
  content: '';
  border-width: 8px 8px 8px 0;
  border-style: solid;
  border-color: transparent #AAAAAA transparent transparent;
}

.gantt-reservation-box-tooltip {
  position: absolute;
  top: -1px;
  left: calc(100% + 8px);
  //height: 32px;
  padding: 7px 10px;

  font-size: 12px;
  color: #444444;

  border-radius: 3px;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #AAAAAA;
  background: #EEEEEE;
  z-index: 10;

  white-space: nowrap;

  user-select: none;

  visibility: hidden;
  opacity: 0;
  box-shadow: 0 2px 4px #00000022;
  transition: all 100ms ease;
}

.gantt-reservation-box-tooltip-tag {
  position: relative;
  top: -1px;
  left: -4px;
  padding: 4px 6px;
  border-radius: 4px;
  color: #EEEEEE;
  background: #444444;
}

.gantt-reservation-line {
  height: 41px;
  overflow: hidden;
  border-bottom: 1px solid #CCC;
}

.gantt-reservation-line-dark {
  background: #88888833;
}

.gantt-reservation-line-dark-border {
  border-bottom: 1px dashed #666;
}

%gantt-reservation-box {
  display: inline-block;
  position: absolute;
  height: 32px;
  margin-top: 4px;
  border-radius: 2px;
  user-select: none;

  // transition: all 100ms ease;
}

.gantt-reservation-box-tools {
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 100%;
  border-width: 0 0 0 1px;
  border-style: solid;
  font-size: 10px;
}

.gantt-reservation-box-tools-icon {
  height: 50%;
  text-align: center;
  transition: all 150ms ease;

  &:hover:not([disabled]) {
    cursor: pointer;
    transition: all 150ms ease;
  }
}

%box-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

%left-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  border-width: 0 0 0 3px;
  border-style: solid;
  cursor: ew-resize;
}

%right-control {
  position: absolute;
  top: 0;
  right: 0;
  width: 6px;
  height: 100%;
  border-width: 0 3px 0 0;
  border-style: solid;
  cursor: ew-resize;
}

%center-control {
  position: absolute;
  top: 4px;
  left: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 2px;
  font-size: 11px;
}

%box-drag-control {
  position: absolute;
  bottom: -6px;
  left: -1px;
  width: calc(100% + 2px);
  height: 10px;
  border-radius: 0 0 4px 4px;
  cursor: grabbing;
}

%box-drag-control-after {
  content: '';
  display: block;
  width: auto;
  height: 4px;
  margin: 3px 4px;
  border: 2px dashed black;
}

%info-tag {
  float: left;
  border-radius: 12px;
  height: 24px;
  width: 24px;
  margin-top: -3px;
  margin-left: -2px;
  padding-top: 4px;

  font-size:11px;
  font-weight: bold;
}

.gantt-reservation-box-content {
  height: 100%;
  padding: 6px 4px 6px 4px;
  font-size: 12px;

  white-space: nowrap;
  text-overflow: clip;
  text-align: center;
  user-select: none;
  overflow: hidden;
}

.gantt-reservation-box-content:hover ~ .gantt-reservation-box-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 100ms ease;
}

.gantt-reservation-box-complete-green {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-green;
  background: $c-box-green-transparent;
  color: $c-box-green-dark;
}

.gantt-reservation-box-complete-delay{
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-darkblue;
  background: $c-box-darkblue-transparent;
  color: $c-box-darkblue-dark;
}

.gantt-reservation-box-complete-cyan {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-cyan;
  background: $c-box-cyan-transparent;
  color: $c-box-cyan-dark;
}

.gantt-reservation-box-at-order {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-yellow;
  background: $c-box-yellow-transparent;
  color: $c-box-yellow-dark;
}

.gantt-reservation-box-reserved {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-orange;
  background: $c-box-orange-transparent;
  color: $c-box-orange-dark;
}

.gantt-reservation-box-selection {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-blue;
  background: $c-box-blue-transparent;
  color: $c-box-blue-dark;
  z-index: auto;
}

.gantt-reservation-box-workshop {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-red-dark;
  background: $c-box-red-transparent;
  color: $c-box-red;
}

.gantt-reservation-box-workshop.muted {
  border-style: dashed;
  opacity: 0.6;
}

.gantt-reservation-box-transfer {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-magenta;
  background: $c-box-magenta-transparent;
  color: $c-box-magenta-dark;
}

.gantt-reservation-box-transfer.muted {
  border-style: dashed;
  opacity: 0.6;
}

.gantt-reservation-box-invalid {
  @extend %gantt-reservation-box;
  border: 1px solid $c-box-red-dark;
  background: $c-box-red-transparent;
  color: $c-box-red;
  animation: blinking 800ms linear 0s infinite alternate;
}

.gantt-reservation-box-selection {
  .box-status {
    padding: 2px 5px;
    background: $c-box-blue-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-blue;
    background: $c-box-blue;
    color: $c-box-blue-light;
  }

  .gantt-reservation-box-tools-icon {
    &:hover:not([disabled]) {
      background: $c-box-blue-light;
      color: $c-box-blue;
    }

    &:disabled {
      color: $c-box-blue-light;
    }
  }
}

.gantt-reservation-box-reserved {
  .box-status {
    padding: 2px 5px;
    background: $c-box-orange-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-orange;
    background: $c-box-orange;
    color: $c-box-orange-light;
  }

  .gantt-reservation-box-tools-icon {
    &:hover:not([disabled]) {
      background: $c-box-orange-light;
      color: $c-box-orange;
    }

    &:disabled {
      color: $c-box-orange-light;
    }
  }

  .box-controls {
    @extend %box-controls;

    .left-control {
      @extend %left-control;
      border-color: $c-box-orange;
    }

    .right-control {
      @extend %right-control;
      border-color: $c-box-orange;
    }

    .center-control {
      @extend %center-control;
      color: $c-box-orange-dark;

      &:hover:not([disabled]) {
        color: $c-box-orange;
      }

      &:disabled {
        color: #{$c-box-orange-dark}88;
      }
    }
  }

  .box-drag-control {
    @extend %box-drag-control;
    background: $c-box-orange;

    &::after {
      @extend %box-drag-control-after;
      border-color: $c-box-orange-light;
    }
  }
}

.gantt-reservation-box-complete-green {
  .box-status {
    padding: 2px 5px;
    background: $c-box-green-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-green;
    background: $c-box-green;
    color: $c-box-green-light;
  }

  .gantt-reservation-box-tools-icon {
    height: 100%;

    &:hover:not([disabled]) {
      background: $c-box-green-light;
      color: $c-box-green;
    }

    &:disabled {
      color: $c-box-green-light;
    }
  }

  .box-controls {
    @extend %box-controls;

    .left-control {
      @extend %left-control;
      border-color: $c-box-green;
    }

    .right-control {
      @extend %right-control;
      border-color: $c-box-green;
    }

    .center-control {
      @extend %center-control;
      color: $c-box-green-dark;

      &:hover:not([disabled]) {
        color: $c-box-green;
      }

      &:disabled {
        color: #{$c-box-green-dark}88;
      }
    }
  }

  .box-drag-control {
    @extend %box-drag-control;
    background: $c-box-green;

    &::after {
      @extend %box-drag-control-after;
      border-color: $c-box-green-light;
    }
  }

  .info-tag {
    @extend %info-tag;
    background: $c-box-green-dark;
    color: $c-box-green-light;
  }
}

.gantt-reservation-box-complete-cyan {
  .box-status {
    padding: 2px 5px;
    background: $c-box-cyan-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-cyan;
    background: $c-box-cyan;
    color: $c-box-cyan-light;
  }

  .gantt-reservation-box-tools-icon {
    height: 100%;

    &:hover:not([disabled]) {
      background: $c-box-cyan-light;
      color: $c-box-cyan;
    }

    &:disabled {
      color: $c-box-cyan-light;
    }
  }

  .box-controls {
    @extend %box-controls;

    .left-control {
      @extend %left-control;
      border-color: $c-box-cyan;
    }

    .right-control {
      @extend %right-control;
      border-color: $c-box-cyan;
    }

    .center-control {
      @extend %center-control;
      color: $c-box-cyan-dark;

      &:hover:not([disabled]) {
        color: $c-box-cyan;
      }

      &:disabled {
        color: #{$c-box-cyan-dark}88;
      }
    }
  }

  .box-drag-control {
    @extend %box-drag-control;
    background: $c-box-cyan;

    &::after {
      @extend %box-drag-control-after;
      border-color: $c-box-cyan-light;
    }
  }

  .info-tag {
    @extend %info-tag;
    background: $c-box-cyan-dark;
    color: $c-box-cyan-light;
  }
}

.gantt-reservation-box-at-order {
  .box-status {
    padding: 2px 5px;
    background: $c-box-yellow-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-yellow;
    background: $c-box-yellow;
    color: $c-box-yellow-light;
  }

  .gantt-reservation-box-tools-icon {
    height: 100%;

    &:hover:not([disabled]) {
      background: $c-box-yellow-light;
      color: $c-box-yellow;
    }

    &:disabled {
      color: $c-box-yellow-light;
    }
  }

  .box-controls {
    @extend %box-controls;

    .left-control {
      @extend %left-control;
      border-color: $c-box-yellow;
    }

    .right-control {
      @extend %right-control;
      border-color: $c-box-yellow;
    }

    .center-control {
      @extend %center-control;
      color: $c-box-yellow-dark;

      &:hover:not([disabled]) {
        color: $c-box-yellow;
      }

      &:disabled {
        color: #{$c-box-yellow-dark}88;
      }
    }
  }

  .box-drag-control {
    @extend %box-drag-control;
    background: $c-box-yellow;

    &::after {
      @extend %box-drag-control-after;
      border-color: $c-box-yellow-light;
    }
  }

  .info-tag {
    @extend %info-tag;
    background: $c-box-yellow-dark;
    color: $c-box-yellow-light;
  }
}

.gantt-reservation-box-invalid {
  .box-status {
    padding: 2px 5px;
    background: $c-box-red-light;
  }

  .gantt-reservation-box-tools {
    border-color: $c-box-red;
    background: $c-box-red;
    color: $c-box-red-light;
  }

  .gantt-reservation-box-tools-icon {
    &:hover:not([disabled]) {
      background: $c-box-red-light;
      color: $c-box-red;
    }

    &:disabled {
      color: $c-box-red-light;
    }
  }

  .box-controls {
    @extend %box-controls;

    .left-control {
      @extend %left-control;
      border-color: $c-box-red;
    }

    .right-control {
      @extend %right-control;
      border-color: $c-box-red;
    }

    .center-control {
      @extend %center-control;
      color: $c-box-red-dark;

      &:hover:not([disabled]) {
        color: $c-box-red;
      }

      &:disabled {
        color: #{$c-box-red-dark}88;
      }
    }
  }

  .box-drag-control {
    @extend %box-drag-control;
    background: $c-box-red;

    &::after {
      @extend %box-drag-control-after;
      border-color: $c-box-red-light;
    }
  }
}

.gantt-absence-line {
  display: inline-block;
  position: absolute;
  height: 4px;
  border-radius: 2px;
  user-select: none;
}

.gantt-absence-line-red {
  background: #FF000066;
}

.gantt-absence-line-orange {
  background: #FF880066;
}

.gantt-conflict-line {
  display: inline-block;
  position: absolute;
  height: 30px;
  border-radius: 2px;
  background: $c-box-blue-transparent;
  border-top: 2px solid $c-box-blue-dark;
  user-select: none;
}

.gantt-inactive-line {
  display: inline-block;
  position: absolute;
  height: 41px;
  border-radius: 2px;
  background: $c-box-red-transparent;
  user-select: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #959fa7;
  background-color: #fff;
}
