.external-note {

}

.note-switch{
    cursor: pointer;
}

.note-switch:hover{
    color: #d30132;
}


.note-active{
    color: #d30132;
}

.main-note {

}