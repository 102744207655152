/* SASS Stylesheet */

@import "./colors";

a:link {
  color: #888888;
}

a:visited {
  color: #888888;
}

a:hover {
  color: $c-kutter-red;
  text-decoration: none;
}

a:active {
  color: $c-kutter-red-dark;
  text-decoration: none;
}

.link-inherit {
  color: inherit !important;
}

body {
  overflow: hidden;
}

h4 {
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
  margin-top: 10px;
  margin-bottom: 30px;
}

#login-form {
  margin: auto;
  top: calc(50vh - 225px);
  width: 400px;
  box-shadow: 0 0 32px 4px #00000044;
}

#login-form .card-header {
  border: none;
  background: $c-kutter-red;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.text-grey,
.text-gray {
  color: $light-grey;
}

.home-link-area {
  position: absolute;
  top: 10px;
  left: 20px;
  width: 220px;
  height: 30px;
}

.home-link-area:hover {
  cursor: pointer;
}

.list-title-bar {
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
}

.order-view-btn {
  display: block;
  padding: 2px 0;
  width: 100%;
  border: none;
  color: #007bff;
  background: #ffffff;
  font-size: 14px;
  transition: all 0.15s ease-in-out;
}

.order-view-btn:hover:not([disabled]) {
  cursor: pointer;
}

.order-view-btn:active {
  outline: none;
}

.order-view-btn:focus {
  outline: none;
}

.order-view-btn.active:not([disabled]) {
  color: #ffffff;
  background: #007bff;
  transition: all 0.15s ease-in-out;
}

.order-view-btn:disabled {
  color: #666666;
}

.order-calendar {
  position: absolute;
  top: 64px;
  left: 100px;
  z-index: 5;
}

.order-calendar-close {
  position: absolute;
  width: 46px;
  height: 46px;
  align-content: center;
  background-color: #ffffff;
  border-top: 1px solid #a0a096;
  border-right: 1px solid #a0a096;
  border-bottom: 1px solid #a0a096;
  top: 64px;
  left: 449px;
  z-index: 5;
}

.orderCalendarButton {
  position: absolute;
  top: 64px;
  left: 75px;
  background-color: white;
  border: 1px solid #a0a096;
  border-right-color: transparent;
  z-index: 10;
}

.orderCalendarButtonExpand::before {
  position: absolute;
  top: 64px;
  left: 75px;
  background-color: white;
  border: 1px solid #a0a096;
  border-right-color: transparent;
  z-index: 10;
}

.orderCalendarButtonExpand {
  position: absolute;
  top: 64px;
  left: -116px;
  background-color: white;
  border: 1px solid #a0a096;
  border-right-color: transparent;
  z-index: 10;
  width: 217px;
  height: 176px;
}

.order-gps-icon {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 8px;
}

.order-status-blob-stroke {
  position: absolute;
  top: calc(50% - 11px);
  left: 8px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: #000000;
}

.order-status-blob-fill {
  position: relative;
  top: -3px;
  left: -3px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background: inherit;
  opacity: 0.5;
}

.order-status-blob-icon {
  position: absolute;
  top: 7px;
  left: 7px;
  color: white;
}

.border-bottom-data-h2 {
  border-bottom: 1px solid #666;
}

.modal {
  display: block;
}

.avatar {
  max-height: 80px;
  max-width: 80px;
  border-radius: 50px;
  border: 1px solid #666;
  float: right;
  margin-top: -80px;
}

.container-fluid {
  height: calc(100vh - 65px);
}

.resource-list-entry,
.employee-absence-head,
.employee-absence-entry,
.employee-list-head,
.employee-list-entry {
  display: flex;
  padding-right: 0;
  padding-left: 0;
}

.employee-absence-head > div,
.employee-absence-entry > div,
.employee-list-head > div,
.employee-list-entry > div {
  padding-top: 10px;
  padding-bottom: 10px;
}

.employee-absence-head > div,
.employee-list-head > div {
  font-weight: bold;
  background-color: #cccccc;
}

.data-panel {
  height: calc(100vh - 65px);
  background: #f9f9f9;
  overflow-y: scroll;
  overflow-x: hidden;
}

.data-panel::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

.permissions-table-body {
  flex: 1 1 auto;
  background: #f9f9f9;
  overflow: scroll;
}

.permissions-table-body::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

.bg-grey {
  background-color: #e2e3e5;
}

.fa-icon-16 {
  width: 16px !important;
  height: 16px !important;
}

.fa-btn {
  padding: 6px;
  margin-left: 5px;
  width: 38px !important;
  height: 38px;
  border-radius: 0.25rem;
  font-size: 20px;
}

.fa-btn-primary {
  color: #007bff !important;
}

.fa-btn-secondary {
  color: #888888;
}

.fa-btn-secondary:disabled {
  color: #88888888;
}

.fa-btn-secondary:hover:not([disabled]) {
  color: #dd0000;
  cursor: pointer;
}

.fa-btn-secondary-disabled {
  //font-size: 20px;
  color: #aaaaaa;
}

.trash {
  border: 1px solid #c82333;
  background-color: #c82333;
}

.times {
  border: 1px solid #218838;
  background-color: #218838;
}

.lock {
  border: 1px solid #e0a800;
  background-color: #e0a800;
}

.center {
  align-self: center;
  width: 50%;
  margin-left: 25%;
  margin-top: 25%;
  text-align: center;
}

.create-absence {
  width: 100%;
  text-align: center;
  line-height: 28px;
  height: 40px;
}

.resources-unlock-btn,
.human-resources-unlock-btn {
  height: 38px;
}

/* Disposition */

#order_detail {
  /*height: calc(100vh - 600px);*/
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
}

#resource_dispo {
  background-color: #f5f5f5;
  /*height: calc(600px - 65px);*/
  padding: 20px;
  overflow: auto;
  border-top: 6px solid #ccc;
}

#dispo_panel {
  background-image: url("/images/gant_background.png");
  height: 100vh;
  position: absolute;
}

#dispo_add_panel {
  height: 100%;
  position: fixed;
  right: 0px;
  overflow: hidden;
  border-left: 6px solid #ccc;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.gantt-machine-filter {
  min-height: 45px;
  width: 320px;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 10;
  display: inline-block;
  vertical-align: top;
}

.gantt-machine-filter-control {
  margin: 1px 0 0 1px;
}

.gantt-machine-filter-options {
  padding: 3px 7px;
  color: white;
  background: #666;
  box-shadow: 0 4px 6px -2px #00000044;
}

.no-margin-left {
  margin-left: 0 !important;
}

.padding-20 {
  padding: 20px 20px 0 20px;
}

.password-error {
  background: red;
}

.login-error {
  text-align: center;
  padding-top: 10px;
  color: red;
}

.permission-module {
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  position: relative;
  flex: 0 0 calc(100% / 7);
  padding: 5px;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
}

.form-group > span {
  float: right;
}

.header {
  height: 65px;
  background: #666666;
}

.header-separator {
  flex-shrink: 0;
  margin: 0 5px;
  width: 3px;
  height: 38px;
  border: 1px solid #777777;
  background: #dddddd;
}

.header-menu-left {
  display: flex;
  align-items: center;
  height: 65px;
  float: left;
  background: #666666;
}

.header-menu-right {
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 65px;
  float: right;
  background: #666666;
}

.header-menu-together {
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc(100vw - 400px);
  display: flex;
  align-items: center;
  height: 65px;
  background: #666666;
}
.header-menu-together::-webkit-scrollbar {
  display: none;
}

.header-dropdown-container {
  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 65px;
  float: right;
}

.header-menu-user-container {
  background: $c-kutter-red;
  height: 65px;
  overflow: hidden;
  float: right;
}

.header-menu-user-container:hover .header-menu-user {
  position: relative;
  top: -65px;
  height: 65px;
  float: right;
  transition: all 300ms ease;
}

.header-menu-user {
  position: relative;
  top: 0;
  padding: 0 16px 0 16px;
  height: 130px;
  font-size: 11px;
  line-height: 20px;
  color: white;
  transition: all 300ms ease;
}

.header-menu-user-account {
  display: flex;
  align-items: center;
  height: 65px;
}

.header-menu-user-logout {
  display: flex;
  align-items: center;
  height: 65px;
  a {
    width: 100%;
  }
}

.header-menu-user svg {
  display: block;
  margin: auto;
  font-size: 26px;
}

.header-establishment-select-container {
  position: absolute;
  top: 46px;
  left: 24px;
  width: 215px;
}

.header-establishment-select-value {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
}

.header-establishment-select-value:hover {
  cursor: pointer;
}

.header-establishment-select-arrow {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 0;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.header-establishment-select-value:hover > .header-establishment-select-arrow {
  visibility: visible;
}

.header-establishment-select-dropdown {
  position: relative;
  top: 10px;
  border-radius: 4px;
  border: 1px solid $c-kutter-red;
  background: #ffffff;
  //box-shadow: 0 2px 4px 1px #00000044;
  z-index: 200;
}

.header-establishment-select-dropdown-item {
  padding: 4px 11px;
  //border-bottom: 1px solid #ffffff;
  color: $c-kutter-red;
}

.header-establishment-select-dropdown-item:first-child {
  padding-top: 6px;
  border-radius: 4px 4px 0 0;
}

.header-establishment-select-dropdown-item:last-child {
  padding-bottom: 4px;
  border-radius: 0 0 4px 4px;
  border: none;
}

.header-establishment-select-dropdown-item:hover {
  background: $c-kutter-red-transparent;
}

.header-establishment-select-dropdown-item.active {
  color: #ffffff;
  background: $c-kutter-red;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.navlink:link {
  color: white;
  text-decoration: none;
  transition: all 100ms linear;
}

.navlink:visited {
  color: white;
  text-decoration: none;
}

.navlink:hover {
  color: white;
  border-radius: 4px;
  background: #555555;
  text-decoration: none;
  transition: all 100ms linear;
}

.navlink:active {
  color: white;
  text-decoration: none;
  background: #444444;
  transition: all 100ms linear;
}

.navlink-btn {
  display: block;
  width: 100%;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  outline: none;
  box-shadow: none;
}

.navlink-btn:focus {
  outline: none;
  box-shadow: none;
}

.navlink-icon {
  display: block;
  margin: 2px auto 0 auto;
  font-size: 26px;
}

.navlink-text {
  font-size: 11px;
  line-height: 20px;
}

.rounded-circle {
  height: 40px;
  width: 40px;
}

.gantt-viewer-container {
  min-height: calc(100vh - 106px);
  max-height: calc(100vh - 106px);
  overflow: scroll;
}

.gantt-viewer-settings {
  position: absolute;
  bottom: 62px;
  right: 20px;
  z-index: 40;
}

.gant-time-line {
  position: sticky;
  background: white;
  height: 15px;
  border-bottom: 1px solid #ccc;
  z-index: 10;
}

.gantt-time-period-head {
  display: inline-block;
  vertical-align: top;
  position: sticky;
  top: 0;
  left: 320px;
  background: white;
  z-index: 5;
}

.gantt-time-period-head-line {
  display: block;
  vertical-align: top;
  height: 15px;
  margin: 0;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.gantt-container {
  position: relative;
  min-width: 100%;
  min-height: 100%;
}

.gantt-bg-days {
  background-image: url("./../images/gantt_bg_days_120.png");
  background-repeat: repeat;
  background-size: 840px 10px;
}

.gantt-bg-hours {
  background-image: url("./../images/gantt_bg_hours_20.png");
  background-repeat: repeat;
  background-size: 3360px 1px;
}

.hour,
.day,
.week,
.month,
.day-80 {
  float: left;
  height: 15px;
  margin: 0;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  border-right: 1px solid #ccc;
}

.hour {
  width: 20px;
}

.day {
  width: 20px;
}

.day-selected {
  width: 20px;
  font-weight: bold;
  color: white;
  background: #47a7fb;
  border-right: 1px solid #ffffff44;
}

.day-80 {
  width: 80px;
}

.day-80-selected {
  width: 80px;
  font-weight: bold;
  color: white;
  background: #47a7fb;
  border-right: 1px solid #ffffff44;
}

.projectLine {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.project {
  min-height: 25px;
  border: 1px solid #999999;
  border-radius: 2px;
  background-color: rgba(110, 110, 110, 0.2);
}

.resource_box {
  background-color: #f5f5f5;
}

.order-box-line,
.order-box-error-line {
  text-align: center;
  margin-top: 2px;
  background-color: #007bff;
  color: #fff;
  line-height: 20px;
  min-height: 20px;
  max-height: 20px;
  border-radius: 0;
  font-size: 10px;
  overflow: hidden;
}

.order-box-error-line {
  line-height: 2px;
  min-height: 2px;
  max-height: 2px;
  background: red;
}

.dispo-title {
  display: inline-block;
  padding: 1px 4px 0 4px;
  height: 23px;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid #999999;
  border-radius: 2px;
  background-color: #6c757d;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.dispo-title-highlight {
  background-color: #cb0035;
}

.drag-handle {
  border-radius: 2px;
  background-color: rgba(0, 123, 255, 0.65);
  top: 0;
  left: 0;
  position: absolute;
}

.drag-handle:hover {
  background-color: #007bff;
}

.drag-handle.dh-horizontal {
  width: 80px;
  height: 6px;
  cursor: row-resize;
  left: calc(50% - 40px);
}
.drag-handle.dh-vertical {
  width: 6px;
  height: 80px;
  cursor: col-resize;
  top: calc(50vh - 6px);
}

.drag-handle .drag-handle-highlight {
  position: absolute;
  border-radius: 2px;
  background-color: #f1f1f1;
}

.drag-handle.dh-horizontal .drag-handle-highlight {
  top: 2px;
  left: 20px;
  width: 40px;
  height: 2px;
}

.drag-handle.dh-vertical .drag-handle-highlight {
  top: 20px;
  left: 2px;
  width: 2px;
  height: 40px;
}

.gantOrderBoxIcon {
  font-size: 12px;
  float: left;
  margin: 4px;
}

.row-highlight {
  background: #6abaf766;
}

.gantt-highlight {
  background: #cb003522;
}

.Select-multi-value-wrapper .Select-value {
  background: #eeeeee;
  border-color: #aaaaaa;
  color: #666666;
}

.Select-multi-value-wrapper .Select-value-icon {
  border-color: #aaaaaa;
}

.Select-multi-value-wrapper .Select-value-icon:hover {
  background: #aaaaaa;
  border-color: #aaaaaa;
  color: #666666;
}

.custom-control-label.checked::before {
  background-color: #007bff;
}

.custom-control-label.checked::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.separator {
  margin: 5px 0;
  width: 100%;
  //height: 1px;
  border-bottom: 1px solid #cccccc;
  //background: #CCCCCC;
}

.border-top-dashed {
  border-top: 1px dashed #cccccc;
}

.btn-no-style {
  display: block;
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.btn-no-style:hover:not([disabled]) {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.order-fixed-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 71px;
  border-left: 1px solid #dee2e6 !important;
  background: #ffffff;
  z-index: 900;
}

.order-fixed-bottom.force-left {
  left: 0;
}

.order-fixed-bottom.force-middle {
  left: 33.333333%;
  width: 25%;
}

.tooltip-info:hover {
  visibility: visible;
  opacity: 1;
}

.col-fixed-7th {
  width: 14.285%;
  min-width: 14.285%;
  max-width: 14.285%;
}

.col-fixed-8th {
  width: 12.5%;
  min-width: 12.5%;
  max-width: 12.5%;
}

.col-fixed-9th {
  width: 11.111111111%;
  min-width: 11.111111111%;
  max-width: 11.111111111%;
}

.col-fixed-10th {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
}

.col-fixed-11th {
  width: 9.090909091%;
  min-width: 9.090909091%;
  max-width: 9.090909091%;
}

.col-fixed-12th {
  width: 8.333333%;
  min-width: 8.333333%;
  max-width: 8.333333%;
}

.clip-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-box-yellow {
  background: $c-box-yellow;
}

.bg-box-grey {
  background: #aaaaaa;
}

.bg-box-lightgrey {
  background: #dddddd;
}

.bg-box-blue {
  background: $c-box-blue;
}

.bg-box-red {
  background: $c-box-red;
}

.bg-box-green {
  background: $c-box-green-dark;
}

.large {
  font-size: 150%;
}

.btn-square {
  width: 45px;
  height: 45px;
}

.select-circle {
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 8px;
  width: 8px;
  height: 16px;
  border-radius: 4px;
  opacity: 0.8;
}

.table-color-bar {
  float: left;
  margin-right: 8px;
  width: 8px;
  height: 100%;
  border-radius: 4px;
}

.btn-outline-success-force-active {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

%cost-center-select-badge {
  padding: 4px 8px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: bold;
  color: #ffffff;
  background: #666666;
}

.cost-center-select-badge-le {
  @extend %cost-center-select-badge;
  color: $c-box-green-light;
  background: $c-box-green;
}

.cost-center-select-badge-du {
  @extend %cost-center-select-badge;
  color: $c-box-cyan-light;
  background: $c-box-cyan;
}

.cost-center-select-badge-ka {
  @extend %cost-center-select-badge;
  color: #666666;
  background: #cccccc;
}

.cost-center-select-badge-wa {
  @extend %cost-center-select-badge;
  color: #856404;
  background-color: #fff3cd;
}

.circle {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0;
  right: 0;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 7px;
}

.stroke-red {
  background-color: red;
  position: absolute;
  width: 3px;
  height: 100%;
  border: 2px;
}

.table-fixed {
  width: 100%;
  tbody {
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
  }
}

.no-scrollbar {
  ::-webkit-scrollbar {
    display: none;
  }
}

.custom-a {
  color: #212529;
}
.custom-a:visited {
  color: #212529;
}
.custom-a:link {
  color: #212529;
}
.custom-a:hover {
  color: #980124;
}

.confirmationModal {
  position: fixed;
  z-index: 100;
  top: 65px;
  background-color: #6c757daa;
  width: 50%;
  height: 100vh;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.modalBox {
  z-index: 120;
  position: relative;
  top: 10%;
  margin-left: 20%;
  margin-right: 20%;
}

.machineRangeBox {
  background: #d4d3d7;
  border: 1px solid #444444;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.machineBox {
  background: #6c757d;
  z-index: 5;
  height: 100%;
  border: none;
}

.machineBoxGradientRight {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    90deg,
    rgba(108, 117, 125, 1) 0%,
    rgba(108, 117, 125, 1) 50%,
    rgba(220, 53, 69, 1) 100%
  );
}

.machineBoxGradientLeft {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    90deg,
    rgba(220, 53, 69, 1) 0%,
    rgba(108, 117, 125, 1) 50%,
    rgba(108, 117, 125, 1) 100%
  );
}

.machineBoxGradientOutside {
  background: rgb(220, 53, 69);
  background: linear-gradient(
    90deg,
    rgba(220, 53, 69, 1) 0%,
    rgba(108, 117, 125, 1) 30%,
    rgba(108, 117, 125, 1) 70%,
    rgba(220, 53, 69, 1) 100%
  );
}

.instanceList {
  max-height: 35vh;
  overflow-y: scroll;
}

.instanceList::-webkit-scrollbar {
  display: none;
}

.spin {
  animation: spin7 2.1s infinite linear;
}

.crossHover:hover {
  color: #dc3545;
}

.dateField {
  border-radius: 3px;
  border: 1px solid #ced4da;
}
.dateField:hover {
  cursor: pointer;
}
