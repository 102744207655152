.badge-outline-primary {
    color: #007bff;
    border: 1px solid #007bff;
}

.mc-btn-no-style {
    padding: 0;
    margin: 0;
    border: none;
    color: inherit;
    background: transparent;
}

.mc-btn-no-style:focus {
    outline: 0;
}

.mc-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000044;
    z-index: 1200;
}

.mc-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.mc-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 15px 5px 5px;
    max-height: 100vh;
    overflow: auto;
    z-index: 900;
}

.mc-wrapper::-webkit-scrollbar {
    visibility: hidden;
    width: 0;
}

.mc-card-width {
    width: 320px;
    transition: all 300ms ease;
}

.mc-card-width-expanded {
    width: 640px;
    transition: all 300ms ease;
}

.mc-card {
    position: relative;
    margin-bottom: 10px;
    padding: 12px;
    /*min-height: min-content;*/
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 4px 1px #00000044;
    overflow: hidden;

    transition: width 300ms ease;
}

.mc-card:first-child {
    margin-top: 65px;
}

.mc-card img {
    object-fit: contain;
    margin: 4px auto 12px auto;
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
}

.mc-card-buttons {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 12px;
    color: #666666;
}

.mc-card-btn:hover {
    color: #007bff;
    cursor: pointer;
}

.mc-card-content {
    margin-bottom: 35px;
}

.mc-card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 6px 12px 10px 12px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    background: #ffffff;
}

.mc-card-footer-news-badge {
    position: absolute;
    top: 10px;
    right: 12px;
}

.mc-filter-container {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 12px;
    width: 664px;
    min-height: 65px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 2px 4px 1px #00000044;
    z-index: 1000;
}
