.tb-btn {
    position: relative;
    width: 42px;
    height: 22px;
    border: 1px solid #CCC;
    border-radius: 4px;
    background: #FFF;
    box-shadow: inset 0 0 3px #00000044;
}

.tb-btn:disabled {
    background: #e9ecef;
}

.tb-btn:hover:not([disabled]) {
    cursor: pointer;
}

.tb-btn:hover:not([disabled]) .tb-handle {
    background: #444;
    transition: all 200ms ease;
}

.tb-btn:hover:not([disabled]) .tb-handle.active {
    background: #980124;
    transition: all 200ms ease;
}

.tb-handle {
    position: absolute;
    top: 1px;
    right: 21px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background: #666;
    transition: all 200ms ease;
}

.tb-handle.active {
    right: 1px;
    background: #D30132;
    transition: all 200ms ease;
}
