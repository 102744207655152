@media print  {
    button {
        display: none !important;
    }

    .container-fluid {
        height: auto!important;
    }

    .order_right_panel {
        height: auto!important;
        overflow: hidden!important;
        padding: 0!important;
    }
}